<template>
  <component
    :is="updateComponent"
    :class="classes"
    :style="styles"
    v-html="html"
  ></component>
</template>

<script>
import store from "../../store";

export default {
  name: "Paragraph",
  props: {
    text: {
      type: String,
      default: "Empty paragraph"
    },
    text2: {
      type: String,
      required: false,
      default: ''
    },
    grid: {
      type: Object,
      default: () => {
        return {
          backgroundColor: "#fff"
        }
      }
    },
    action: {
      type: [Object, Array]
    },
    variables: Array,

    conditionalText: {
      type: Boolean,
      required: false,
      default: false,
    },

    styles: {
      type: Object,
      default: () => {
        return {};
      }
    },
    classes: {
      type: String,
      default: ""
    }
  },
  computed: {
    updateComponent() {
      if (this.classes.includes('heading')) {
        return 'h1'
      } else if (this.classes.includes('subHeading')) {
        return 'h2'
      } else {
        return 'p'
      }
    },
    html() {
      let result = this.$t(this.text);
      if (this.variables) {
        for (const variable of this.variables) {
          let value = this.$store.state[variable];
          result = result.replace(`{{${variable}}}`, value);
        }
      }
      return result;
    }
  },
  mounted() {
    if (document.querySelector('.modalOpener')) {
      document.querySelectorAll('.modalOpener').forEach(x => {
        x.addEventListener('click', function () {
          store.commit('setModalOptions', {
            type: 'custom',
            message: this.dataset.text,
            isActive: true,
            closeButton: true
          })
        })
      })
    }
  },
  updated() {
    if (document.querySelector('.modalOpener')) {
      document.querySelectorAll('.modalOpener').forEach(x => {
        x.addEventListener('click', function () {
          store.commit('setModalOptions', {
            type: 'custom',
            message: this.dataset.text,
            isActive: true,
            closeButton: true
          })
        })
      })
    }
  },
  methods: {
    // updateComponent: debounce(function (event) {
    //   this.$emit("component-updated", {
    //     text: event.target.innerText
    //   });
    // }, 2000),
    // async triggerActions () {
    //   console.log("CLICK")
    //   if (this.action.length > 0) {
    //     for (let i = 0; i < this.action.length; i++) {
    //       const act = this.action[i];
    //       const fn = act.fn;
    //       const params = act.params;
    //       await this[fn](params, this);
    //     }
    //     return;
    //   }
    // }
  }
};
</script>

<style lang="scss" scoped>

.header-text {
  font-style: normal;
  font-weight: 700;
  font-size: 56px;
  line-height: 64px;
  color: #4E4B66;
}

.subheader-text {
  font-style: normal;
  font-weight: 400;
  font-size: 26px;
  line-height: 38px;
  color: #3E3D40;
}

.addPadding {
  @media(max-width: 450px) {
    padding-top: 20px !important;
    padding-bottom: 20px !important;
  }
}

.paragraphBg {
  @media(max-width: 450px) {
    background: rgba(235, 243, 248, 0.7);
  }
}

.text-center {
  text-align: center;
}
</style>
