export default {
  // 'Log in to your account': '',
  // 'Email': '',
  // 'Password': '',
  // 'Forgot your password?': '',
  // 'Login': '',
  'Confirm your identity and claim your refund': 'Confirme ton identité',
  'Verify your identity by entering your phone number here. It is only used once for verification and not for advertising purposes. After the action, your phone number will be deleted by us.': 'Saisi ton numéro de téléphone pour confirmer ton identité. Cette étape permet de vérifier ton identité. Toutes tes données seront ensuite supprimées.',
  'Phone number': 'Numéro de téléphone',
  'Get SMS Code': 'Recevoir un SMS',
  'Verification code': 'Saisis le code de confirmation du SMS',
  'We sent you a verification code via sms': 'Nous vous avons envoyé un code de confirmation par SMS',
  'Didnt receive it?': 'Tu n\'as pas reçu de SMS ?',
  'Click here': 'Clique ici',
  'Change sms number': 'Changer de numéro de téléphone portable ?',
  'Provide bank details': 'Communique tes coordonnées bancaires',
  'Please let us know to which account the refund amount should be transferred.': 'Merci de communiquer les coordonnées du compte sur lequel le montant doit être remboursé',
  'Account holder first name': 'Prénom du titulaire du compte',
  'Account holder last name': 'Nom de famille du titulaire du compte',
  // 'Enter the frist name of the account holder': '',
  // 'Enter the last name of the account holder': '',
  'Enter your IBAN number': 'Saisis l\'IBAN',
  // 'Continue': '',
  'Send': 'CONFIRMER',
  'Next': 'CONTINUER',
  // 'Back': '',
  'Enter address': 'Entre l\'adresse',
  'To take part in this campaign we need your address. This will only be used for this campaign and will be deleted once you have completed your participation.': 'Pour participer à cette campagne, nous avons besoin de ton adresse. Celle-ci ne sera utilisée que pour cette campagne et sera supprimée une fois ta participation terminée.',
  // 'We got your bank information, and we’ll inform you via email when we’ll send the money to your bank account': '',
  'Back to campaign page': 'RETOUR À LA PAGE D\'ACCUEIL',
  'Back to forme page': 'Retour à la page d\'accueil de ForMe',
  // 'receipt-image': '',
  'Upload a receipt': 'Charger la preuve d\'achat',
  'In this step, please upload the proof of purchase of your products.': 'Dans cette étape, veuillez charger la preuve d\'achat de ton produit.',
  'Drop your photo here or select file.': 'Clique ici pour sélectionner le fichier ou télécharge la preuve d\'achat par glisser-déposer.',
  'Only JPG, PNG and PDF files are supported Maximum file size: 10MB': 'Seuls les fichiers JPEG, PNG et PDF sont pris en charge. Taille maximale du fichier : 10 Mo',
  'Only JPG and PNG files are supported Maximum file size: 10MB': 'Seuls les fichiers JPEG et PNG sont pris en charge. Taille maximale du fichier : 10 Mo',
  'UPLOAD': 'CHARGER',
  'The following information must be visible:': 'Les informations suivantes doivent être clairement lisibles :',
  'Folded or scanned receipts or invoices will not be accepted': 'Les justificatifs ou factures pliés ou scannés ne peuvent pas être acceptés.',
  'Retailer / Shop': 'Distributeur / Marché',
  'Date and time of purchase': 'Date & heure d\'achat',
  'Campaign product and its price': 'Produit en promotion & prix d\'achat',
  'Total sum of the receipt / invoice and applied VAT': 'Total de la preuve d\'achat & TVA déclarée.',
  'Receipt / Invoice ID (not the bank transaction ID)': 'Numéro de ticket de la casse / numéro de facture',
  'Valid': 'Valide',
  'Hard to read': 'Difficile à lire',
  'Folded': 'Froissé',
  'Cut off': 'Coupé',
  // 'Your receipt is now being loaded and processed.': '',
  // 'The receipt will be processed automatically. This may take a minute. Please be patient.': '',
  // 'If you get an error message, please get a better version of the receipt and upload it again.': '',
  // 'Please drag your receipt inside the gray rectangle.': '',
  // 'You can erase private information with the Eraser tool.': '',
  // 'Crop': '',
  // 'Erase': '',
  // 'Drag': '',
  // 'Zoom in': '',
  // 'Zoom out': '',
  'Please upload your product photo here': 'Veuillez charger une photo de ton produit à cette étape.',
  'Please upload a photo of the back of your product.': 'Veuillez charger une photo du dos (code-barres) de ton produit.',
  'Thank you': 'Merci',
  'You\'re almost there! What happens now?': '<div class="thank-you-text-iframe"><span class="header">Tu y es presque! Que se passe-t-il maintenant?</span><ol><li>Nous vérifions tes données (cela peut prendre jusqu\'à 36 heures);</li><li>Dès que le processus de vérification est terminé, tu recevras un e-mail de notre part.</li></ol><span>Ton numéro de référence est le suivant: <span class="ref">{{referenceNumber}}</span></span>Merci de ta participation!</div>',
  'Thank you for resubmitting your bank details. So there should now be a successful transfer.': 'Merci d\'avoir mis à jour tes coordonnées bancaires. Une nouvelle tentative de virement sera effectuée.',
  // 'This file format is not supported. Only .jpeg, .png and .pdf files are accepted.': "",
  'This file format is not supported. Only .jpeg and .png files are accepted.': "Le format de fichier n'est pas pris en charge. Seuls les fichiers .jpeg et .png sont acceptés.",
  'This file is too big. Only files up to 10MB can be uploaded.': "Le fichier est trop volumineux. Seuls les fichiers d'une taille maximale de 10 Mo sont pris en charge.",
  // 'Copied to clipboard': '',
  // 'Oops!!!': '',
  // 'Oops, something went wrong. Please try again.': '',
  // 'Error!!! Something went wrong!': '',
  // 'Error!!! Something went wrong, start over!': '',
  // 'The connection you are trying to use has already expired. Please request a new password reset link.': '',
  // 'An error occurred while loading the receipt because it has been loaded too many times.': '',
  // 'Verification code could not be sent to the phone number as it is not allowed. Please check the number you entered and try again.': '',
  // 'Incorrect SMS code': '',
  // 'Error. There was a server error. Please try again later.': '',
  // 'This is not a receipt, please upload a valid receipt photo.': '',
  // 'Error, please try again later': '',
  // 'Dear Customer, you entered an incorrect email/password. You must log in with the account information you used to participate in the campaign.': '',
  // 'Business UsePayout Page – Wrong SMS Code Entry Dear Customer, you entered the wrong SMS code. Please try again.': '',
  // 'Unfortunately the link is no longer valid': '',
  'Your photo has been successfully submitted to us and is now being checked. As soon as your successful participation in the campaign has been confirmed by checking your data, you will receive a corresponding email. If it turns out that your data was incomplete or the purchase receipt could not be recorded, you will receive an email with relevant queries. The testing process can take up to 36 hours; We therefore ask you to be patient.': "Ta photo nous a été transmise avec succès et est en cours de vérification. Dès que la vérification des données aura confirmé la réussite de la participation à la campagne, tu recevras un e-mail à ce sujet. S'il s'avère que tes données sont incomplètes ou que la preuve d'achat n'a pas pu être saisie, tu recevras un e-mail avec les questions correspondantes. Le processus de vérification peut prendre jusqu'à 36 heures, nous te demandons donc d'être patient.",
  // 'Upload proof of purchase + product back': '',
  // 'In this step, please upload the purchase receipt for your product together with the back of the product. Please make sure that the PZN of the product is clearly visible.': '',
  'Name': 'Prénom',
  'Last name': 'Nom de famille',
  'Street': 'Rue',
  'House number': 'Numéro de maison',
  'City': 'Ville',
  'Zip Code': 'Code postal',
  'Country': 'Pays',
  '': '',
  'Terms and conditions': 'Conditions de participation',


  errors: { // Google Translate
    default: "Oups, quelque chose s'est mal passé !",
    customerToken: "Oups, quelque chose s'est mal passé ! S'il vous plaît, recommencez depuis le début !",
    invalidCode: "Malheureusement, la séance est déjà terminée. Veuillez demander un nouveau lien pour réinitialiser votre mot de passe.",
    manyTries: "Une erreur s'est produite car le reçu a été téléchargé trop de fois.",
    generateCode: "Le code de vérification n'a pas pu être envoyé car il s'agit d'un numéro de téléphone non autorisé. Veuillez vérifier le numéro de mobile fourni et réessayer.",
    wrongCode: "Le code de vérification est incorrect",
    network: "Erreur. Une erreur de serveur s'est produite. Veuillez réessayer plus tard.",
    invalidReceipt: "Le fichier que vous avez téléchargé ne constitue pas une preuve d'achat. Veuillez télécharger une photo de reçu valide.",
    "Campaign not found!": "Une erreur s'est produite, veuillez réessayer plus tard.",
    "User not found": "Cher client, vous avez mal saisi soit l'adresse e-mail, soit le mot de passe. Vous devez vous connecter avec les mêmes informations que celles que vous avez utilisées pour participer à la promotion.",
    "Login Failed": "L'e-mail ou le mot de passe est incorrect. Veuillez saisir votre adresse e-mail et votre mot de passe exactement tels que vous les avez fournis lors de votre inscription. Les lettres majuscules et minuscules doivent être respectées.",
    "Login failed": "L'e-mail ou le mot de passe est incorrect. Veuillez saisir votre adresse e-mail et votre mot de passe exactement tels que vous les avez fournis lors de votre inscription. Les lettres majuscules et minuscules doivent être respectées.",
    "Code is incorrect": "Cher client, le code de confirmation que vous avez fourni est incorrect. Veuillez réessayer.",
    "Error sending verification code": "Le code de vérification n'a pas pu être envoyé car il s'agit d'un numéro de téléphone mobile non approuvé. Veuillez vérifier le numéro de téléphone mobile fourni et réessayer.",
    "Email is not the same as the user journey": "L'e-mail ou le mot de passe est incorrect. Veuillez saisir votre adresse e-mail et votre mot de passe exactement tels que vous les avez fournis lors de votre inscription. Les lettres majuscules et minuscules doivent être respectées."
  },
  dictionary: { // Google Translate
    fr: {
      messages: {
        "ibanName": "{_field_} ne peut contenir que des lettres, des traits d'union et des espaces",
        "iban": "IBAN invalide",
        "phoneNumber": "Numéro de téléphone invalide",
        plz: "Code postal invalide",
      },
      names: {
        email: "E-Mail",
        password: "Mot de passe",
        iban: "IBAN",
        firstname: "Prénom",
        firstName: "Prénom",
        lastname: "Nom de famille",
        lastName: "Nom de famille",
        phoneNumber: "Numéro de téléphone",
        code: "Le code de vérification",
        postal: "Code postal",
        plz: "Code postal",
        strasse: "Rue",
        hausnr: "Numéro de maison",
        city: "Ville",
        country: "Pays",
        ort: "Ville",
        street: "Rue",
        number: "Numéro de maison",
        land: "Pays",
        campaignName: "Nom de la campagne",
        userEmail: "Adresse email",
        supportCaseTitle: "Problème"
      }
    }
  }
}
