export default {
  'Welcome back': 'Καλώς ήρθες και πάλι!',
  'You are a few steps away from completing your participation! Please fill in the information you will be asked for in the next steps, to receive a refund!': `Βρίσκεσαι λίγα βήματα πριν την ολοκλήρωση της συμμετοχής σου!
  <br>
  <br>
  Παρακαλούμε συμπλήρωσε τα στοιχεία που θα σου ζητηθούν στα επόμενα βήματα, προκειμένου να λάβεις επιστροφή χρημάτων.`,
  'User Login': 'Σύνδεση Χρήστη',
  'Log in as a user of epithimies.gr': 'Συνδέσου ως χρήστης του <a href="https://www.epithimies.gr" target="_blank">epithimies.gr</a>',
  'Log in to your account': 'Είσοδος στο λογαριασμό σας',
  'LOG IN TO YOUR ACCOUNT': 'ΣΥΝΔΕΣΟΥ ΣΤΟ ΛΟΓΑΡΙΑΣΜΟ ΣΟΥ',
  'LOGIN': 'ΣΥΝΔΕΣΗ',
  'Email': 'Email',
  'Password': 'Κωδικός',
  'Forgot your password?': 'Ξεχάσατε τον κωδικό σας;',
  'Confirm your details and get a refund': 'Επιβεβαίωσε τα στοιχεία σου και πάρε επιστροφή χρημάτων',
  'Verify your details by entering your mobile number here. Your number will only be used once and only for the purpose of verifying your details and not for advertising purposes. After verifying your details, we will delete your phone number.': 'Επαλήθευσε τα στοιχεία σου εισάγοντας τον αριθμό του κινητού σου εδώ. Ο αριθμός σου θα χρησιμοποιηθεί μόνο μία φορά και μόνο για τους σκοπούς της επαλήθευσης των στοιχείων σου και όχι για διαφημιστικούς σκοπούς.<span style="color: red;"><b>Μετά από την επαλήθευση των στοιχείων σου, θα διαγράψουμε τον αριθμό τηλεφώνου σου.<b></span>',
  'Cell phone number': 'Αριθμός κινητού',
  'GET THE CONFIRMATION CODE (SMS)': 'ΛAΒΕ ΤΟΝ ΚΩΔΙKO ΕΠΙΒΕΒΑIΩΣΗΣ (SMS)',
  'Enter the confirmation code': 'Εισαγωγή του κωδικού επιβεβαίωσης',
  'We sent you a verification code via sms': 'Σου στείλαμε έναν κωδικό επαλήθευσης μέσω SMS',
  'Didnt receive it?': '', // EMPTY DUE TO DOUBLE TEXT, could not delete from template due to other locales
  'Click here': 'Δεν τον έλαβες; Κάνε κλικ εδώ;',
  'Change mobile number': 'Αλλαγή αριθμού κινητού',
  'SUBMISSION': 'ΥΠΟΒΟΛΗ',
  'You can fill in your bank details after the code is confirmed': 'Μπορείς να συμπληρώσεις τα τραπεζικά σου στοιχέια μόλις γίνει επιβεβαίωση του κωδικού που έλαβες.',
  'IBAN DETAILS': 'ΣΤΟΙΧΕΙΑ IBAN',
  'Please fill in the name details below to receive the refund amount you are entitled to': 'Παρακαλούμε συμπλήρωσε τα παρακάτω στοιχεία (αριθμός ΙΒΑΝ, όνομα και επώνυμο δικαιούχου), ώστε να λάβεις το ποσό της επιστροφής χρημάτων που δικαιούσαι.',
  "The field is mandatory, please fill in only the basic account number without the country code (GR)": "Το πεδίο είναι υποχρεωτικό, παρακαλούμε συμπλήρωσε μόνο τoν βασικό αριθμό λογαριασμού χωρίς τον κωδικό χώρας (GR)",
  'Account holder first name': 'Όνομα κατόχου λογαριασμού',
  'Account holder last name': 'Επώνυμο κατόχου λογαριασμού',
  'Enter the frist name of the account holder': 'Εισαγάγετε το όνομα του κατόχου του λογαριασμού',
  'Enter the last name of the account holder': 'Εισαγάγετε το επώνυμο του κατόχου του λογαριασμού',
  'Enter your IBAN number': 'Εισαγάγετε το IBAN σας',
  'Use only Latin characters!': '<img src="/assets/images/blue-info.svg"/>Χρησιμοποίησε μόνο λατινικούς χαρακτήρες!',
  'ATTENTION: In case the information you filled in is not correct, the transaction will not be completed successfully.': '<img src="/assets/images/bell-disclaimer.svg" style="margin-right: 10px;"/><span style="color: #003DA5;"><b>ΠΡΟΣΟΧΗ: Σε περίπτωση που τα στοιχεία που συμπλήρωσες δεν είναι ορθά, η συναλλαγή δεν θα ολοκληρωθεί με επιτυχία.<b></span>',
  'Send': 'ΥποβολH',
  'SEND': 'ΥΠΟΒΟΛH',
  'Submit': 'ΥποβολH',
  'Back': 'Πίσω',
  'We got your bank information, and we’ll inform you via email when we’ll send the money to your bank account': 'λάβαμε τα τραπεζικά σας στοιχεία και θα σας ενημερώσουμε μέσω email όταν θα στείλουμε τα χρήματα στον τραπεζικό σας λογαριασμό',
  'We have received the information you uploaded. Thank you very much for your participation.': 'Εχουμε λάβει τις πληροφορίες που υπέβαλες.',
  'After checking your new data, you will receive a confirmation email.': 'Μετά την ολοκλήρωση του ελέγχου των νέων δεδομένων σου, θα λάβεις ένα μήνυμα επιβεβαίωσης μέσω ηλεκτρονικού ταχυδρομείου.',
  'In the event that the information you submitted was insufficient or the photo of your purchase receipt is not legible, you will receive a message with relevant instructions via email. This process can take up to 36 hours to complete. Thank you for your understanding.': 'Σε περίπτωση που οι πληροφορίες που υπέβαλες ήταν ανεπαρκείς ή η φωτογραφία της απόδειξης αγοράς σου δεν είναι ευδιάκριτη, θα λάβεις ένα μήνυμα με σχετικές οδηγίες μέσω ηλεκτρονικού ταχυδρομείου. Η ολοκλήρωση της συγκεκριμένης διαδικασίας μπορεί να διαρκέσει <b>έως και 36 ώρες</b>. Σε ευχαριστούμε για την κατανόηση.',
  'After we have successfully verified your purchase receipt and all other data, we will send you an email confirming your participation in the promotion.': 'Αφού ελέγξουμε με επιτυχία την απόδειξη αγοράς σου και όλα τα υπόλοιπα δεδομένα, θα σου αποστείλουμε ένα email που θα επιβεβαιώνει τη συμμετοχή σου στην προωθητική ενέργεια.',
  'Εάν έχεις ερωτήσεις, επικοινώνησε με την ομάδα υποστήριξής μας support@epithimiespromo.gr': 'Εάν έχεις ερωτήσεις, επικοινώνησε με την ομάδα υποστήριξής μας <a href="mailto:support@epithimiespromo.gr">support@epithimiespromo.gr</a>',
  'Back to page': 'ΠΙΣΩ ΣΤΗ ΣΕΛΙΔΑ ΤΗΣ ΕΝΕΡΓΕΙΑΣ',
  'Back to epithimies page':"ΠΗΓΑΙΝΕ ΣΤΗ ΣΕΛΙΔΑ EPITHIMIES",
  'product-upload-heading': 'Φροντισε να ειναι εμφανης ο αριθμος barcode του προϊοντος.',
  'Back to campaign page': 'ΠΙΣΩ ΣΤΗ ΣΕΛΙΔΑ ΤΗΣ ΚΑΜΠΑΝΙΑΣ',
  'receipt-image': 'assets/images/png/receipt-epithimies.png',
  'Upload a receipt': 'Ανεβάστε μια απόδειξη',
  'In this step, please upload the proof of purchase of your products.': 'Σε αυτό το βήμα, παρακαλούμε ανέβασε την απόδειξη αγοράς του/των προϊόντος/προϊόντων σου.',
  'Fill in your bank details': 'Συμπλήρωσε τα τραπεζικά στοιχεία σου',
  'Please fill in the details of the bank account to which the refund amount will be transferred.': 'Παρακαλούμε συμπλήρωσε τα στοιχεία του τραπεζικού λογαριασμού στον οποίο θα μεταφερθεί το ποσό επιστροφής χρημάτων.',
  'Drop your photo here or select file.': 'Σύρετε τη φωτογραφία σας εδώ ή επιλέξτε αρχείο',
  'Only JPG, PNG and PDF files are supported Maximum file size: 10MB': 'Υποστηρίζονται μόνο αρχεία JPG, PNG και PDF Μέγιστο μέγεθος αρχείου: 10MB',
  'Only JPG and PNG files are supported Maximum file size: 10MB': 'Υποστηρίζονται μόνο αρχεία JPG και PNG Μέγιστο μέγεθος αρχείου: 10MB',
  'UPLOAD': 'ΑΝΕΒΑΣΜΑ',
  'The following information must be visible:': 'Οι ακόλουθες πληροφορίες πρέπει να είναι ορατές:',
  'Folded or scanned receipts or invoices will not be accepted': 'Διπλωμένες ή σκαναρισμένες αποδείξεις ή τιμολόγια δεν θα γίνονται δεκτά',
  'Retailer / Shop': 'Μαγαζί / Κατάστημα',
  'Date and time of purchase': 'Ημερομηνία και ώρα αγοράς',
  'Campaign product and its price': 'Προϊόν καμπάνιας και η τιμή του',
  'Total sum of the receipt / invoice and applied VAT': 'Σύνολο απόδειξης / τιμολογίου και εφαρμοζόμενου ΦΠΑ',
  'Receipt / Invoice ID (not the bank transaction ID)': 'Αναγνωριστικό απόδειξης / τιμολογίου (όχι το αναγνωριστικό τραπεζικής συναλλαγής)',
  'Valid': 'Έγκυρο',
  'Hard to read': 'Δυσανάγνωστο',
  'Folded': 'Διπλωμένο',
  'Cut off': 'Κομμένο',
  'Your receipt is now being loaded and processed.': 'Η απόδειξή σας ανεβαίνει και υποβάλλεται σε επεξεργασία.',
  'The receipt will be processed automatically. This may take a minute. Please be patient.': 'Η απόδειξη θα υποβληθεί σε αυτόματη επεξεργασία. Αυτό μπορεί να διαρκέσει ένα λεπτό. Παρακαλώ κάντε λίγο υπομονή.',
  'If you get an error message, please get a better version of the receipt and upload it again.': 'Εάν λάβετε ένα μήνυμα σφάλματος, αποκτήστε μια καλύτερη έκδοση του βύσματος και μεταφορτώστε το ξανά.',
  'Please drag your receipt inside the gray rectangle.': 'Σύρετε την απόδειξή σας μέσα στο γκρι ορθογώνιο.',
  'You can erase private information with the Eraser tool.': 'Μπορείτε να διαγράψετε προσωπικά δεδομένα με το εργαλείο Eraser.',
  'Crop': 'Περικοπή',
  'Erase': 'Διαγραφή',
  'Drag': 'Μεταφορά',
  'Zoom in': 'Μεγέθυνση',
  'Zoom out': 'Σμίκρυνση',
  'Please upload your product photo here': 'Ανεβάστε τη φωτογραφία του προϊόντος σας εδώ',
  'Please upload a photo of the back of your product.': 'Ανεβάστε μια φωτογραφία από το πίσω μέρος του προϊόντος σας.',
  'Thank you': 'Ευχαριστώ',
  'Thank you for resubmitting your bank details. So there should now be a successful transfer.': 'Σας ευχαριστούμε που υποβάλατε ξανά τα τραπεζικά σας στοιχεία. Τώρα μπορεί να γίνει επιτυχής μεταφορά.',
  'This file format is not supported. Only .jpeg, .png and .pdf files are accepted.': 'Αυτή η μορφή αρχείου δεν υποστηρίζεται. Αποδεκτά μόνο αρχεία .jpeg, .png και .pdf.',
  'This file format is not supported. Only .jpeg and .png files are accepted.': 'Αυτή η μορφή αρχείου δεν υποστηρίζεται. Αποδεκτά μόνο αρχεία .jpeg και .png.',
  'This file is too big. Only files up to 10MB can be uploaded.': 'Αυτό το αρχείο είναι πολύ μεγάλο. Μπορούν να μεταφορτωθούν μόνο αρχεία έως 10 MB.',
  'Thank you for your participation': 'Ευχαριστουμε για την συμμετοχη',
  'Your proof of purchase and the information you submitted have been successfully sent to us and are being reviewed.': 'Η απόδειξη αγοράς σου και οι πληροφορίες που υπέβαλες έχουν αποσταλεί με επιτυχία σε εμάς και βρίσκονται σε διαδικασία ελέγχου.',
  'Reference number:': 'Αριθμος αναφορας:',
  'Copied to clipboard': 'Αντιγράφηκε στο πρόχειρο',
  'Next steps': 'Επομενα βηματα',
  'Your phone number was entered and your bank details were sent successfully. You will soon receive a confirmation email about the progress of your participation.': 'Η καταχώρηση του αριθμού τηλεφώνου σου και η αποστολή των τραπεζικών στοιχείων σου έγινε με επιτυχία. Σύντομα θα λάβεις ενα email επιβεβαίωσης για την πορεία της συμμετοχής σου.',
  'The refund will be made within 5 days from the completion of the control and validation process of your participation. In case there is a problem with your membership and the support team needs to contact you, the refund will be completed within 30 days.': 'Η επιστροφή των χρημάτων θα γίνει εντός <b>5 ημερών</b> από την ολοκλήρωση της διαδικασίας ελέγχου και εγκυρότητας της συμμετοχής σου. Σε περίπτωση που υπάρχει κάποιο πρόβλημα με τη συμμετοχή σου και χρειάζεται να επικοινωνήσει η ομάδα υποστήριξης μαζί σου, η επιστροφή χρημάτων θα ολοκληρωθεί εντός 30 ημερών.',
  'If a problem occurs, we will contact you again via email.': 'Σε περίπτωση που προκύψει κάποιο πρόβλημα, θα επικοινωνήσουμε και πάλι μαζί σου μέσω μηνύματος ηλεκτρονικού ταχυδρομείου.',
  'If you have questions, contact our support team support@epithimiespromo.gr': 'Εάν έχεις ερωτήσεις, επικοινώνησε με την ομάδα υποστήριξής μας <a href="mailto:pgcashback@epithimiespromo.gr"><b>pgcashback@epithimiespromo.gr</b></a>',
  'Once the refund has been successfully completed to the account you stated, you will receive a relevant confirmation email.': 'Μόλις ολοκληρωθεί με επιτυχία η επιστροφή των χρημάτων στον λογαριασμό που δήλωσες, θα λάβεις ένα σχετικό επιβεβαιωτικό email.',
  'OOH!': 'ΩΩΧ!',
  'Something went wrong!': 'Κάτι πήγε στραβά!',
  'Error!!! Something went wrong!': 'Τα στοιχεία πιστοποίησης δεν ταιριάζουν με τον συμμετέχοντα χρήστη ή τουλάχιστον ένα από αυτά είναι λάθος',
  'SOMETHING WENT WRONG, START THE PROCESS ALL OVER!': 'ΚΑΤΙ ΠΗΓΕ ΣΤΡΑΒΑ, ΞΕΚΙΝΗΣΕ ΤΗ ΔΙΑΔΙΚΑΣΙΑ ΑΠΟ ΤΗΝ ΑΡΧΗ!',
  'The connection you are trying to use has already expired. Please request a new password reset link.': 'Η σύνδεση που προσπαθείτε να χρησιμοποιήσετε έχει ήδη λήξει. Ζητήστε έναν νέο σύνδεσμο επαναφοράς κωδικού πρόσβασης.',
  'An error occurred while loading the receipt because it has been loaded too many times.': 'Παρουσιάστηκε σφάλμα κατά τη φόρτωση επειδή φορτώθηκε πάρα πολλές φορές.',
  'Verification code could not be sent to the phone number as it is not allowed. Please check the number you entered and try again.': 'Δεν ήταν δυνατή η αποστολή του κωδικού επαλήθευσης στον αριθμό τηλεφώνου, καθώς δεν επιτρέπεται. Ελέγξτε τον αριθμό που εισαγάγατε και δοκιμάστε ξανά.',
  'Incorrect SMS code': 'Λανθασμένος κωδικός SMS',
  'Error. There was a server error. Please try again later.': 'Λάθος. Παρουσιάστηκε σφάλμα διακομιστή. Παρακαλώ δοκιμάστε ξανά αργότερα.',
  'This is not a receipt, please upload a valid receipt photo.': 'Αυτή δεν είναι απόδειξη, ανεβάστε μια έγκυρη φωτογραφία απόδειξης.',
  'Error, please try again later': 'Λάθος, παρακαλώ δοκιμάστε ξανά.',
  'Dear Customer, you entered an incorrect email/password. You must log in with the account information you used to participate in the campaign.': 'Αγαπητέ πελάτη, εισαγάγατε λάθος email/κωδικό πρόσβασης. Πρέπει να συνδεθείτε με τα στοιχεία λογαριασμού που χρησιμοποιήσατε για να συμμετάσχετε στην καμπάνια.',
  'Business UsePayout Page – Wrong SMS Code Entry Dear Customer, you entered the wrong SMS code. Please try again.': 'Business Use Payout Page – Λανθασμένη καταχώριση κωδικού SMS Αγαπητέ πελάτη, πληκτρολογήσατε λάθος κωδικό SMS. Παρακαλώ προσπαθήστε ξανά.',
  'Unfortunately the link is no longer valid': 'Δυστυχώς ο σύνδεσμος έληξε',
  'Please contact the support team. You will find the relevant email address in the message we sent you.': 'Παρακαλούμε να επικοινωνήσεις με την ομάδα υποστήριξης στο <a style="color: #00A3E0 !important;" href="mailto:pgcashback@epithimiespromo.gr" target="_blank">pgcashback@epithimiespromo.gr</a>.',
  '': '',
  'Request your payout': "ΣΤΟΙΧΕΙΑ IBAN",
  'Please enter the information of your account to which the refund amount will be transferred.': "Παρακαλούμε συμπλήρωσε τα παρακάτω στοιχεία για να λάβεις το ποσό της επιστροφής χρημάτων που δικαιούσαι.",

  errors: {
    default: 'Τα στοιχεία πιστοποίησης δεν ταιριάζουν με τον συμμετέχοντα χρήστη ή τουλάχιστον ένα από αυτά είναι λάθος',
    customerToken: 'Λάθος!!! Κάτι πήγε στραβά, ξεκινήστε από την αρχή!',
    invalidCode: 'Η σύνδεση που προσπαθείτε να χρησιμοποιήσετε έχει ήδη λήξει. Ζητήστε έναν νέο σύνδεσμο επαναφοράς κωδικού πρόσβασης.',
    manyTries: 'Παρουσιάστηκε σφάλμα κατά τη φόρτωση επειδή φορτώθηκε πάρα πολλές φορές.',
    generateCode: 'Δεν ήταν δυνατή η αποστολή του κωδικού επαλήθευσης στον αριθμό τηλεφώνου, καθώς δεν επιτρέπεται. Ελέγξτε τον αριθμό που εισαγάγατε και δοκιμάστε ξανά.',
    wrongCode: 'Λανθασμένος κωδικός SMS',
    network: 'Λάθος. Παρουσιάστηκε σφάλμα διακομιστή. Παρακαλώ δοκιμάστε ξανά αργότερα.',
    invalidReceipt: 'Αυτή δεν είναι απόδειξη, ανεβάστε μια έγκυρη φωτογραφία απόδειξης.',
    'Campaign not found!': 'Λάθος, παρακαλώ δοκιμάστε ξανά.',
    'User not found': 'Αγαπητέ πελάτη, εισαγάγατε λάθος email/κωδικό πρόσβασης. Πρέπει να συνδεθείτε με τα στοιχεία λογαριασμού που χρησιμοποιήσατε για να συμμετάσχετε στην καμπάνια.',
    'Login Failed': 'Αγαπητέ πελάτη, εισαγάγατε λάθος email/κωδικό πρόσβασης. Πρέπει να συνδεθείτε με τα στοιχεία λογαριασμού που χρησιμοποιήσατε για να συμμετάσχετε στην καμπάνια.',
    'Login failed': 'Αγαπητέ πελάτη, εισαγάγατε λάθος email/κωδικό πρόσβασης. Πρέπει να συνδεθείτε με τα στοιχεία λογαριασμού που χρησιμοποιήσατε για να συμμετάσχετε στην καμπάνια.',
    'Code is incorrect': 'Business Use Payout Page – Λανθασμένη καταχώριση κωδικού SMS Αγαπητέ πελάτη, πληκτρολογήσατε λάθος κωδικό SMS. Παρακαλώ προσπαθήστε ξανά.',
    mobileLimit: 'Σύμφωνα με τους όρους και προϋποθέσεις, κάθε μοναδικός αριθμός κινητού μπορεί να χρησιμοποιηθεί έως 3 φορές μέχρι το πέρας της ενέργειας. Ο αριθμός κινητού που συμπλήρωσες έχει χρησιμοποιηθεί πάνω από 3 φορές, επομένως δεν είναι έγκυρος.',
    ibanLimit: 'Σύμφωνα με τους όρους και προϋποθέσεις, κάθε μοναδικός αριθμός ΙΒΑΝ μπορεί να χρησιμοποιηθεί έως 5 φορές μέχρι το πέρας της ενέργειας. Ο αριθμός ΙΒΑΝ που συμπλήρωσες έχει χρησιμοποιηθεί πάνω από 5 φορές, επομένως δεν είναι έγκυρος.'
  },
  dictionary: {
    gr: {
      messages: {
        'name': 'όνομα',
        'ibanName': 'Το {_field_} μπορεί να περιέχει μόνο γράμματα, παύλες και κενά.',
        'iban': 'Μη έγκυρο IBAN',
        'ibanBank': 'Μη έγκυρο IBAN',
        'phoneNumber': 'Μη έγκυρος αριθμός τηλεφώνου',
      },
      names: {
        email: 'Email',
        password: 'Κωδικός',
        iban: 'IBAN',
        firstname: 'Όνομα',
        firstName: 'Όνομα',
        lastname: 'Επώνυμο',
        lastName: 'Επώνυμο',
        phoneNumber: 'Αριθμός τηλεφώνου',
        code: 'Κωδικός',
      }
    }
  },

}
