<template>
  <ValidationObserver ref="form">
    <form
      class="row"
      :id="fieldId"
      :style="styles"
    >
      <template v-for="field in fields">
        <componentRenderer
          :class="`col-lg-${field.properties.grid.columns}`"
          :field="field"
          @component-updated="updated"
          :key="field.id"
        />
      </template>
      <!-- <button @click="submit">SUBMIT</button> -->
    </form>
  </ValidationObserver>
</template>
<script>
import componentRenderer from "@/components/component-renderer.vue";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "FormComponent",
  components: { componentRenderer },
  props: {
    fieldId: String,
    requires: Array,
    successAction: {
      type: [Object, Array]
    },
    endpoint: {
      type: String,
      required: true
    },
    fields: {
      type: Array,
      required: true
    },
    styles: {
      type: Object,
      required: false,
      default: () => {
      }
    },
    keepInState: Array
  },
  computed: {
    ...mapGetters(["customerToken", "campaignId", "receiptToken" , "referenceNumber", "country", "hasUnresolvedDependencies"]),
    dependencies () {
      return this.hasUnresolvedDependencies(this.requires);
    }
  },
  methods: {
    ...mapActions(['lastStep', 'lastStepIframe', 'payOut', 'uploadAsset']),
    clear () {
      this.fields.forEach(field => {
        if (field.fieldType === "text-input") {
          field.properties.text = "";
        } else if (field.fieldType === "select-input") {
          field.properties.selectedValue = "";
        } else if (field.fieldType === "radio-input") {
          field.properties.selectedValue = "";
        } else if (field.fieldType === "checkbox") {
          field.properties.checked = "";
        } else if (field.fieldType === "date-input") {
          field.properties.value = "";
        }
      });
    },
    async getFieldData (fields, formData) {
      for (const field of fields) {
        if (field.fieldType === "text-input") {
          const name = field.properties.name;
          let value = field.properties.text;
          if (name === "iban") {
            value = this.country ? this.country + value : value
          }
          formData[name] = name === "iban" ? value.replace(/\s/g, '') : value;
        } else if (field.fieldType === "select-input") {
          const name = field.properties.name;
          const value = field.properties.selectedValue;
          formData[name] = value;
        } else if (field.fieldType === "radio-input") {
          const name = field.properties.name;
          const value = field.properties.selectedValue;
          formData[name] = value;
        } else if (field.fieldType === "checkbox") {
          const name = field.properties.name;
          const value = field.properties.checked;
          formData[name] = value;
        } else if (field.fieldType === "date-input") {
          const name = field.properties.name;
          const value = field.properties.value;
          formData[name] = value;
        } else if (field.fieldType === "url-token") {
          const name = field.properties.name;
          const value = field.properties.text;
          formData[name] = value;
        } else if (field.fieldType === "checkbox-terms") {
          const name = field.properties.name;
          const value = field.properties.checked;
          formData[name] = value;
        } else if (field.fieldType === "dynamic-select-input") {
          const name = field.properties.name;
          const value = field.properties.selectedValue;
          formData[name] = value;
        } else if (field.fieldType === "file-input") {
          if (field.properties.value) {
            const value = await this.uploadAsset(field.properties.value).then(res => { return res.file })
            if (value) {
              const fileArr = field.properties.addToArray
              if (fileArr && !formData[fileArr]) formData[fileArr] = []

              if (fileArr) formData[fileArr].push(value)
              else {
                const name = field.properties.name
                formData[name] = value
              }
            }
          }
        } else if (field.properties.fields) {
          await this.getFieldData(field.properties.fields, formData);
        }
      }
    },
    async getFormData (formId) {
      if (this.fieldId === formId) {
        if (this.hasUnresolvedDependencies(this.requires)) {
          this.scrollTo(`#${this.requires[0]}`)
          this.$eventHub.$emit("done-event-trigger", {
            parent: this,
            status: false
          });
          return;
        }
        let formData = {};
        const endpoint = this.endpoint;
        if (this.locale) {
          formData.lang = this.locale;
        }
        formData.customerToken = this.customerToken;
        formData.campaignId = this.campaignId;
        formData.receiptToken = this.receiptToken;
        await this.getFieldData(this.fields, formData)
        // console.log("SENDING FORM>>", formData);
        this.$refs.form.validate().then(isValid => {
          if (isValid) {
            const presistentFormFields={}
            if(this.keepInState){
              this.keepInState.forEach(field=>{
                presistentFormFields[field] = this.$store.state.formData[field]
              })
            }
            this.$store.dispatch("sendFormData", { formData, endpoint }).then(async r => {
                if (r.response && this.successAction) {
                  this.$emit("component-updated")
                  if (formId == 'lastStepForm' || formId == 'lastStepHungary') {
                    let extraData = {}
                    if (formId == 'lastStepHungary') extraData.isDonations = this.$store.state['isDonations'] 
                    let resp2 = await this.lastStep({ endpoint: '/lastStep', extraData })
                    if (resp2) {
                      const fn = this.successAction.fn;
                      const params = this.successAction.params;
                      this[fn](params, this);
                      this.$eventHub.$emit("done-event-trigger", {
                        parent: this,
                        status: true
                      });
                    } else {
                      this.$eventHub.$emit("done-event-trigger", {
                        parent: this,
                        status: true
                      });
                    }
                  } else if (formId == 'p4y0utF0RM') {
                    let resp2 = await this.payOut('/payout')
                    if (resp2) {
                      const fn = this.successAction.fn;
                      const params = this.successAction.params;
                      this[fn](params, this);
                      this.$eventHub.$emit("done-event-trigger", {
                        parent: this,
                        status: true
                      });
                    } else {
                      this.$eventHub.$emit("done-event-trigger", {
                        parent: this,
                        status: true
                      });
                    }
                  } else if (formId == 'lastStepIframe') {
                    let resp2 = await this.lastStepIframe('/joltxIframeLastStep')
                    if (resp2) {
                      const fn = this.successAction.fn;
                      const params = this.successAction.params;
                      this[fn](params, this);
                      this.$eventHub.$emit("done-event-trigger", {
                        parent: this,
                        status: true
                      });
                    } else {
                      this.$eventHub.$emit("done-event-trigger", {
                        parent: this,
                        status: true
                      });
                    }
                    
                    // Check if it has iframe and send message to parent
                    if (this.successAction.iframeEvent) {
                      window.top.postMessage(
                        { request: this.successAction.iframeEvent.event, value: this.successAction.iframeEvent.value },
                        '*'
                      )
                    }
                  } else {
                    const fn = this.successAction.fn;
                    const params = this.successAction.params;
                    this[fn](params, this);
                    this.$eventHub.$emit("done-event-trigger", {
                      parent: this,
                      status: true
                    });

                    // Check if it has iframe and send message to parent
                    if (this.successAction.iframeEvent) {
                      window.top.postMessage(
                        { request: this.successAction.iframeEvent.event, value: this.successAction.iframeEvent.value },
                        '*'
                      )
                    }
                  }
                }
                if (r.response && this.successState) {
                  this.successState.forEach(variable=>{
                    this.$store.commit("setField", { field: variable.state, value: r[variable.form] })
                  })
                }
                this.$eventHub.$emit("done-event-trigger", {
                  parent: this,
                  status: false,
                  message: r.message || "Unexpected Error",
                  invalid_fields: r.invalid_fields || []
                });
              })
              .catch(e => {
                if (this.isTester && process.env.NODE_ENV == 'development') {
                  this.$emit("component-updated")
                  const fn = this.successAction.fn;
                  const params = this.successAction.params;
                  this[fn](params, this);
                }
                if (this.successAction.iframeEvent) {
                  window.top.postMessage(
                    { request: this.successAction.iframeEvent.event, value: 'false' },
                    '*'
                  )
                }
              }).finally(()=>{
                this.$eventHub.$emit("done-event-trigger", {
                  parent: this,
                  status: false,
                });
              });
          } else {
            this.$eventHub.$emit("done-event-trigger", {
              parent: this,
              status: false
            });
            const element = document.getElementById(this.fieldId);
            if (element) {
              element.scrollIntoView({block: "start", behavior: "smooth"});
            }
          }
        });
      }
    },
    updated (field, properties) {
      let updatedField = this._.find(this.fields, function (fld) {
        return fld.fieldId === field.fieldId;
      });

      updatedField.properties = { ...updatedField.properties, ...properties };
      // let updatedField = __.findDeep(this.fields, fld => fld === field.fieldId);
      // console.log(
      //   "component renderer on updated::",
      //   field,
      //   "properties",
      //   properties,
      //   "FOUND",
      //   updatedField
      // );

      // TODO
      // some validation will go here
      // in case if all fields are valid we are happy to set truthy status
      this.$store.dispatch("setComponentStatus", {
        component: this.fieldId,
        status: true
      });
    }
  },
  mounted () {
    this.$eventHub.$on("getFormData-event", this.getFormData);
  }
};
</script>
