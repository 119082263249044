<template>
  <div class="lang-switcher">
    <div
      v-if="i18n.locale == 'fr'"
      @click="switchLocale('de')"
      class="lang"
    >
      <img
        src="@/assets/icons/flags/de.svg"
        alt="Flag of Germany"
      >
      <div>Wechseln zu Deutsch</div>
    </div>
    <div
      v-else-if="i18n.locale == 'de'"
      @click="switchLocale('fr')"
      class="lang"
    >
      <img
        src="@/assets/icons/flags/fr.svg"
        alt="Flag of France"
      >
      <div>Passer au français</div>
    </div>
  </div>
</template>

<script>
import i18n from '@/translate';
import { localize } from 'vee-validate'

export default {
  name: 'DeFrLangSwitcher',
  data () {
    return {
      i18n: i18n,
      frCampaigns: [
        "30390",
        "30391",
        "30418",
        "30420"
      ]
    }
  },
  beforeMount () {
    const campaignId = this.$route.query.campaignId
    if (this.frCampaigns.includes(campaignId) && this.i18n.locale == 'de') {
      this.switchLocale('fr')
    }
  },
  methods: {
    switchLocale (newLanguage) {
      i18n.locale = newLanguage
      const dict = i18n.messages[newLanguage].dictionary[newLanguage]
      localize(newLanguage, dict)
    }
  }
}
</script>

<style scoped lang="scss">
.lang-switcher {
  min-width: 240px;
  height: 40px;
  background-color: #12a3df99;
  border-radius: 8px;

  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;

  &:hover {
    background-color: #12a3df50;
  }

  .lang {
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 1rem;
    min-width: fit-content;
    cursor: pointer;
    height: 100%;
    width: 100%;

    img {
      max-height: 20px;
      max-width: 30px;
    }
  }
}
</style>